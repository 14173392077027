.kv {
    padding: 48px 0;

    @include breakpoint-up('md') {
        padding: 9.306vw 0 152px;
    }

    @include breakpoint-up('xlg') {
        padding: 134px 0 152px;
    }

    .col-lg-8,
    .col-lg-4 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    h1 {
        font-weight: normal;

        strong {
            color: var(--blue-accent);
        }
    }

    p {
        font-size: 16px;
        font-weight: bold;
        line-height: 140%;
        letter-spacing: -0.5px;
        font-family: "Plus Jakarta Sans", sans-serif;

        @include breakpoint-up('md') {
            font-size: 24px;
        }
    }
}

.circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 87.569vw;
    height: 87.986vw;
    background: url('../media/circle.svg') no-repeat bottom right;
    background-size: 100%;
    opacity: 0.4;
}

.team {
    width: 100%;
    background: url('../media/team.jpg') no-repeat center top;
    background-size: auto 250px;
    padding-top: 250px;

    @media (min-width: 495px) {
        background-size: auto 50.505vw;
        padding-top: 50.505vw;
    }

    @include breakpoint-up('md') {
        padding-top: 0;
        height: 48.125vw;
        margin-bottom: 180px;
        background-size: cover;
        background-position: center;
    }

    &-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 100%;
    }

    &-info {
        width: 100%;
        position: relative;
        background-color: #121417;
        padding: 32px 0;

        @include breakpoint-up('md') {
            padding: 0;
            bottom: -110px;
            background-color: transparent;
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .grid {
            width: 100%;
        }

        h2 {
            margin: 0 0 8px;
            border: 0;
            outline: none;
        }

        h3 {
            font-family: "Plus Jakarta Sans", sans-serif;
            font-size: 20px;
            line-height: 120%;
            letter-spacing: -0.4px;
            color: var(--blue-accent);
        }
    }
}

.case-study {
    background: var(--grey-dark) url('../media/tatjana.jpg') no-repeat no-repeat center top;
    background-size: 100%;
    padding: 67vw 0 52px;

    @include breakpoint-up('md') {
        padding: 9.894vw 0 8.54vw;
        background-position: right bottom;
        background-size: 62vw;
    }

    @include breakpoint-up('lg') {
        padding: 8.194vw 0 6.944vw;
    }

    @include breakpoint-up('xlg') {
        background-size: 51vw;
    }

    @media (min-width: 2250px) {
        background-size: 44vw;
    }

    blockquote {
        padding: 0;
        margin: 0;

        @include breakpoint-up('lg') {
            padding-right: 2.083vw;
        }

        p {
            font-size: 24px;
            font-weight: bold;
            line-height: 120%;
            letter-spacing: -1.6px;

            @include breakpoint-up('md') {
                font-size: 2.778vw;
            }

            @include breakpoint-up('xlg') {
                font-size: 40px;
            }
        }

        .author {
            display: block;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: -0.4px;
            font-family: "Plus Jakarta Sans", sans-serif;
            color: var(--blue-accent);
            margin-bottom: 24px;
            font-weight: bold;

            @include breakpoint-up('md') {
                font-size: 1.389vw;
                margin-bottom: 70px;
            }

            @include breakpoint-up('xlg') {
                font-size: 20px;
            }
        }
    }
}

.circle-2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 79.583vw;
    height: 66.389vw;
    background: url('../media/circle-2.svg') no-repeat left top;
    background-size: 100%;

    @include breakpoint-down('lg') {
        display: none;
    }
}

.services {
    position: relative;
    padding: 6.111vw 0 8.333vw;

    h2 {
        margin-bottom: 6.111vw;
    }

    .article-swiper {
        @include breakpoint-down('lg') {
            margin-right: -25px;
        }
    }

    article {
        img {
            width: 100%;
        }

        .article-body {
            padding: 14px 0;

            @include breakpoint-up('lg') {
                padding: 20px;
            }

            h4 {
                font-size: 16px;
                font-weight: 700;
                line-height: 140%;
                margin-bottom: 16px;
                letter-spacing: -0.48px;

                @include breakpoint-up('md') {
                    font-size: 24px;
                    margin-bottom: 18px;
                }
            }
        }
    }

    .cta {
        margin-top: 46px;
        display: flex;
        justify-content: center;
    }
}

.section-header {
    margin-bottom: 28px;

    @include breakpoint-up('lg') {
        margin-bottom: 100px;
    }

    h2 {
        margin: 0;
        color: var(--blue-dark);
    }

    .col-12 {
        display: flex;
        align-items: flex-end;

        &:last-child {
            @include breakpoint-up('lg') {
                justify-content: flex-end;    
            }

            p {
                font-size: 16px;
                font-weight: bold;
                line-height: 120%;
                letter-spacing: -0.4px;
                font-family: "Plus Jakarta Sans", sans-serif;
                color: var(--waikawa-grey);
                margin: 0;

                @include breakpoint-up('lg') {
                    max-width: 386px;
                    font-size: 20px;
                }
            }
        }
    }
}

.exceptional-team {
    background-color: var(--white);
    color: var(--black);
    padding: 42px 0 62px;
    position: relative;
    z-index: 10;

    @include breakpoint-up('lg') {
        padding: 5.556vw 0;
    }

    .blockquote-container {
        img {
            max-width: 100%;
        }
    }

    .img {
        display: none;

        @include breakpoint-up('lg') {
            display: block;
        }
    }

    .col-lg-8 {
        display: flex;
        align-items: flex-end;
    }

    blockquote {
        padding: 0;
        margin: 0;

        @include breakpoint-up('lg') {
            padding: 0 0 0 24px;
        }

        p {
            font-size: 24px;
            font-weight: bold;
            line-height: 120%;
            letter-spacing: -1.6px;

            @include breakpoint-up('md') {
                font-size: 40px;
            }
        }

        .author {
            font-size: 16px;
            line-height: 120%;
            letter-spacing: -0.4px;
            font-family: "Plus Jakarta Sans", sans-serif;
            color: var(--blue-accent);
            font-weight: 700;
            margin-bottom: 16px;

            @include breakpoint-up('md') {
                font-size: 20px;
            }
        }

        span {
            font-size: 14px;
            line-height: 120%;
            letter-spacing: -0.4px;
            color: var(--waikawa-grey);

            @include breakpoint-up('md') {
                font-size: 18px;
            }
        }

        .blockquote-media {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 24px;
            width: 100%;

            @include breakpoint-up('lg') {
                display: block;
            }

            img {
                max-width: 100%;

                @include breakpoint-up('lg') {
                    display: none;
                }
            }
        }
    }
}

.heroes {
    padding: 110px 0 0;

    @include breakpoint-up('lg') {
        padding: 11.111vw 0 5.556vw;
    }

    h3 {
        text-align: center;
        color: var(--blue-dark);
        margin-bottom: 30px;

        @include breakpoint-up('md') {
            margin-bottom: 2.778vw;
        }
    }
}

.heroes-swiper {
    margin-bottom: 16px;
    padding: 0 25px;

    @include breakpoint-up('md') {
        padding: 0 0 0 40px;
        margin-bottom: 40px;
    }
}

.arrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @include breakpoint-up('md') {
        justify-content: flex-end;
        height: 100%;
        margin-bottom: 0;
    }
}

.swiper-button-next-custom {
    display: flex;
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='42' height='43' viewBox='0 0 42 43' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='21' cy='21.4152' r='20' stroke='%230C4574' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19.2725 15.3649L25.5449 21.6373L19.2725 27.9097' stroke='%230C4574' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;

    &:hover {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='40' height='41' viewBox='0 0 40 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20.4152' r='20' fill='%230C4574'/%3E%3Cpath d='M18.2725 14.3649L24.5449 20.6373L18.2725 26.9097' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
    }
}

.swiper-button-prev-custom {
    display: flex;
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Button / Arrow'%3E%3Ccircle id='Ellipse 1' cx='21' cy='20.9749' r='20' transform='rotate(180 21 20.9749)' stroke='%230C4574' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath id='Rectangle 2' d='M22.7276 27.0251L16.4552 20.7527L22.7276 14.4803' stroke='%230C4574' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    margin-right: 8px;

    &:hover {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='42' height='41' viewBox='0 0 42 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20.9999' cy='20.3649' r='20' transform='rotate(-179.962 20.9999 20.3649)' fill='%230C4574'/%3E%3Cpath d='M22.7234 26.4163L16.4552 20.1397L22.7317 13.8715' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
    }
}

.hero-card {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blue-soft-light);
    height: 72px;
    padding: 17px 36px;
    transition: background-color 0.2s ease-in-out;
    
    img {
        max-width: 100%;
        max-height: 100%;
    }

    @include breakpoint-up('md') {
        height: 12.917vw;
    }

    &:hover {
        background-color: rgba(0, 142, 207, .4);
        transition: background-color 0.2s ease-in-out;
    }
}

.map {
    padding: 59vw 0 52px;
    background: var(--blue-dark) url('../media/map.jpg') no-repeat center top;
    background-size: 100%;

    @include breakpoint-up('md') {
        padding: 14.722vw 0 13.056vw;
        background-position: left bottom;
        background-size: 62vw;
    }

    @include breakpoint-up('xlg') {
        background-size: 51vw;
    }

    @media (min-width: 2250px) {
        background-size: 44vw;
    }

    blockquote {
        margin: 0;

        @include breakpoint-up('md') {
            text-align: right;
        }

        p {
            font-size: 24px;
            font-weight: bold;
            line-height: 120%;
            letter-spacing: -1.6px;
            margin-bottom: 24px;

            @include breakpoint-up('md') {
                font-size: 2.778vw;
                margin-bottom: 3.056vw;
            }
            
            @include breakpoint-up('lg') {
                font-size: 40px;
            }
        }
    
        h4 {
            display: block;
            font-size: 1.389vw;
            line-height: 120%;
            letter-spacing: -0.4px;
            font-family: "Plus Jakarta Sans", sans-serif;
            color: var(--blue-accent);
            margin-bottom: 3.472vw;
    
            @include breakpoint-up('lg') {
                font-size: 1.3rem;
            }
        }
    }
}

.linkedin-section {
    background-color: var(--white);
    padding: 42px 0;

    @include breakpoint-up('lg') {
        padding: 6.111vw 0 8.333vw;
    }

    .ln-plugin {
        img {
            max-width: 100%;
        }
    }
}

.cta-section {
    padding: 68vw 0 50px;
    background: var(--blue-dark) url('../media/match.jpg') no-repeat center top;
    background-size: 100%;

    @include breakpoint-up('md') {
        padding: 19.028vw 0 16.667vw;
        background-position: left bottom;
        background-size: 63vw;
    }

    blockquote {
        margin: 0;

        h2 {
            margin-bottom: 24px;

            @include breakpoint-up('md') {
                margin-bottom: 64px;
                max-width: 700px;
                float: right;
            }
        }

        @include breakpoint-up('md') {
            text-align: right;
        }
    }
}