.sub-kv {
    padding: 4.375vw 0 10.278vw;

    &-single {
        padding: 4.375vw 0 5.972vw;
    }

    .col-lg-8,
    .col-lg-4 {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    h1 {
        @include breakpoint-up('lg') {
            padding-right: 150px;
        }

        @include breakpoint-up('xlg') {
            padding-right: 70px;
        }
    }

    p {
        font-size: 16px;
        font-weight: bold;
        line-height: 120%;
        letter-spacing: -0.5px;
        font-family: "Plus Jakarta Sans", sans-serif;
        margin: 0 ;

        @include breakpoint-up('md') {
            font-size: 20px;
        }

        a {
            color: var(--white);
            text-decoration: underline;
            
            &:hover {
                color: var(--blue-accent);
            }
        }
    }
}

.circle-3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 52.083vw;
    height: 52.083vw;
    background: url(../media/circle-3.svg) no-repeat;
    background-size: contain;
    z-index: 1;
    opacity: 0.8;
}

.sub-kv-img {
    display: flex;
    width: 100%;
    height: 188px;

    @include breakpoint-up('md') {
        height: 31.944vw;
    }

    &.our-benefits {
        background: url(../media/our-benefits.jpg) no-repeat center;
        background-size: cover;
    }

    &.our-journey {
        background: url(../media/our-journey.jpg) no-repeat center;
        background-size: cover;
    }

    &.contact-us {
        background: url(../media/contact-us.jpg) no-repeat center;
        background-size: cover;
    }
}