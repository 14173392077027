$columns-count: 12;

:root {
  --container-padding: 25px;
  --content-width: calc(1248px + (2 * var(--container-padding)));
  --grid-row-gap: 25px;
  --grid-column-gap: 25px;

  @include breakpoint-up('md') {
    --grid-row-gap: 25px;
    --grid-column-gap: 25px;
    --container-padding: 25px;
  }
  @include breakpoint-up('lg') {
    --grid-row-gap: 16px;
    --grid-column-gap: 16px;
    --container-padding: 16px;
  }
}

.container {
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  width: 100%;
  margin: 0 auto;

  &:not(.container--fluid) {
    max-width: var(--content-width);
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat($columns-count, 1fr);
  grid-gap: var(--grid-row-gap) var(--grid-column-gap);

  &.no-gap {
    grid-gap: 0 0;
  }
  &.no-row-gap {
    grid-row-gap: 0;
  }
  &.no-column-gap {
    grid-column-gap: 0;
  }
}

@for $i from 0 through $columns-count {
  .col-#{$i} {
    grid-column-end: span #{$i};
  }
  .offset-#{$i} {
    grid-column-start: #{$i + 1};
  }
}

@each $key, $value in $breakpoints {
  @include breakpoint-up($key) {
    @for $i from 0 through $columns-count {
      .col-#{$key}-#{$i} {
        grid-column-end: span #{$i};
      }
      .offset-#{$key}-#{$i} {
        grid-column-start: #{$i + 1};
      }
    }

    .hide-#{$key}-up {
      display: none;
    }
  }

  @include breakpoint-down($key) {
    @for $i from 0 through $columns-count {
      .hide-#{$key}-down {
        display: none;
      }
    }
  }
}