$breakpoints: ('xs': 460px, 'sm': 545px, 'md': 768px, 'lg': 1140px, 'xlg': 1680px);

@mixin breakpoint-up($breakpoint) {
  $value: map-get($breakpoints, $breakpoint);

  @if $value != null {
    @media (min-width: $value) {
      @content;
    }
  }
}

@mixin breakpoint-down($breakpoint) {
  $value: map-get($breakpoints, $breakpoint);

  @if $value != null {
    @media (max-width: ($value - 1)) {
      @content;
    }
  }
}

@mixin breakpoint-only-up($breakpoint) {
  $value: map-get($breakpoints, $breakpoint);

  @if $value != null {
    @media (min-width: 0) and (max-width: ($value - 1)) {
      @content;
    }
  }
}