.blue-section {
    background-color: var(--blue-dark);
    padding: 97px 0 123px;

    @include breakpoint-up('md') {
        padding: 10.694vw 0;
    }

    h3 {
        margin-bottom: 4.861vw;
    }

    h5 {
        margin-bottom: 25px;
    }

    .btn {
        margin-bottom: 25px;
    }

    p {
        margin-bottom: 25px;

        &.no-bottom {
            margin-bottom: 0;
        }

        a {
            color: var(--white);
            text-decoration: underline;
            
            &:hover {
                color: var(--blue-accent);
            }
        }
    }
}
