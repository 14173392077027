.checkbox {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    min-height: 24px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    a {
        color: var(--blue-accent);

        &:hover {
            text-decoration: underline;
        }
    }

    &:hover input ~ .checkmark {
        background-color: var(--white);
    }

    input:checked ~ .checkmark {
        background-color: var(--blue-accent);
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: var(--white);
        border-radius: 4px;
        border: 2px solid var(--blue-accent);

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    .checkmark:after {
        left: 8px;
        top: 3px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.mc_fieldset {
    padding: 0;
    border: 0;
    margin: 0 0 24px;
}

.mergeRow {
    &.error {
        .checkbox {
            .checkmark {
                border-color: var(--red);
            }
        }
        
        .error-text {
            display: block;
        }
    }

    .error-text {
        color: var(--red);
        font-size: 14px;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: normal;
        padding: 0;
        display: none;
        margin: 6px 0 0;
    }
}

#mce-success-response {
    color: var(--white);
    font-size: 24px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: normal;
    padding: 0;
    margin: 6px 0 0;
    font-weight: bold;
}