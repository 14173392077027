h1 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 48px;
    margin: 0;

    @include breakpoint-up('md') {
        font-size: 5vw;
    }

    @include breakpoint-up('xlg') {
        font-size: 96px;
    }
}

h2 {
    margin: 0;
    font-size: 40px;
    line-height: 110%;
    letter-spacing: -2.88px;

    @include breakpoint-up('md') {
        font-size: 5vw;
    }

    @include breakpoint-up('xlg') {
        font-size: 72px;
    }
}

h3 {
    margin: 0;
    font-size: 34px;
    line-height: 110%;
    letter-spacing: -1.9px;

    @include breakpoint-up('lg') {
        font-size: 2.500vw;
    }

    @include breakpoint-up('xlg') {
        font-size: 48px;
    }
}

h4 {
    margin: 0;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.96px;

    @include breakpoint-up('md') {
        font-size: 2.778vw;
    }

    @include breakpoint-up('xlg') {
        font-size: 40px;
    }
}

h5 {
    margin: 0;
    font-size: 22px;
    line-height: 120%;
    letter-spacing: -0.96px;

    @include breakpoint-up('md') {
        font-size: 2.500vw;
    }

    @include breakpoint-up('xlg') {
        font-size: 36px;
    }
}

p {
	margin: 0 0 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.36px;

    @include breakpoint-up('md') {
        margin: 0 0 24px;
        font-size: 18px;
    }
}

.text {
    transition: color 0.3s;
}

.blue {
    color: var(--blue-accent);
}