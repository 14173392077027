.portfolio {
    .bgroup-container {
        @include breakpoint-down('md') {
            padding-right: 0;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                bottom: 2px;
                left: 0;
                width: 100%;
                height: 2px;
                background: var(--white);
                z-index: -1;
            
            }
        }
    }

    .button {
        display: inline-block;
        padding: 4px 10px;
        border: none;
        color: var(--white);
        font-family: sans-serif;
        font-size: 16px;
        background: transparent;
        cursor: pointer;
        font-family: "Plus Jakarta Sans", sans-serif;
        position: relative;

        @include breakpoint-up('md') {
            font-size: 20px;
        }

        &:hover {
            color: var(--blue-accent);

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 100%;
                height: 2px;
                background: var(--blue-accent);
                z-index: 10;
            }
        }

        &:active,
        &.is-checked {
            color: var(--blue-accent);

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 100%;
                height: 2px;
                background: var(--blue-accent);
                z-index: 10;
            }
        }

        &:active {
            color: var(--blue-accent);
        }
    }

    .button-group {
        padding: 2px 0;
        margin-bottom: 47px;
        overflow-x: auto;

        @include breakpoint-up('md') {
            margin-bottom: 57px;
        }

        .button-group-wrap {
            display: flex;
            min-width: 920px;
            white-space: nowrap;
            border-bottom: 2px var(--white) solid;
        }

        &:after {
            content: '';
            display: block;
            clear: both;
        }

        .button {
            float: left;
            border-radius: 0;
            margin-left: 0;
            margin-right: 1px;

            &:first-child {
                border-radius: 0.5em 0 0 0.5em;
            }

            &:last-child {
                border-radius: 0 0.5em 0.5em 0;
            }
        }
    }

    .grid-portfolio {
        @include breakpoint-up('md') {
            margin-left: -30px;
        }

        @include breakpoint-up('lg') {
            margin-left: -40px;
        }
    }

    .element-item {
        position: relative;
        float: left;
        max-width: 100%;
        width: 100%;
        margin-bottom: 40px;
        color: var(--white);

        @include breakpoint-up('md') {
            margin-left: 4.2%;
            max-width: 45.8%;
            margin-bottom: 70px;
        }

        @include breakpoint-up('lg') {
            margin-left: 3.11%;
            max-width: 30.2%;
        }

        .portfolio-img {
            display: flex;
            width: 100%;
            height: 186px;
            align-items: center;
            justify-content: center;
            background-color: var(--blue-soft-light);
            margin-bottom: 8px;
            overflow: hidden;

            a {
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
            }

            img {
                max-width: 100%;
                max-height: 130px;
            }

            &:hover {
                background-color:  var(--white);
            }
        }

        p {
            font-size: 16px;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.4px;
            margin-bottom: 8px;
            font-family: "Plus Jakarta Sans", sans-serif;

            @include breakpoint-up('md') {
                font-size: 20px;
            }
        }

        ul {
            display: flex;

            li {
                list-style: none;
                margin-left: 8px;
                padding-left: 8px;
                color: var(--blue-accent);
                font-size: 14px;
                font-weight: 700;
                line-height: 140%;
                font-family: "Plus Jakarta Sans", sans-serif;
                border-left: 1px var(--blue-accent) solid;

                &:first-child {
                    margin-left: 0;
                    padding-left: 0;
                    border-left: none;
                }
            }
        }

        > * {
            margin: 0;
            padding: 0;
        }

    }
}