header {
    position: relative;
    z-index: 100;
    color: var(--white);
    padding: 22px 0;
    top: 0;
    transition: all 0.3s ease-in-out;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        @include breakpoint-down('lg') {
            img {
                width: 70px;
            }
        }

        @include breakpoint-down('md') {
            img {
                width: 45px;
            }
        }
    }

    &.sticky {
        position: sticky;
        background-color: var(--midnight);
        padding: 15px 0;
        top: 0;
        transition: all 0.3s ease-in-out;

        .logo {
            img {
                width: 45px;
            }
        }
    }

    &.hide {
        top: -200px;
        transition: all 0.3s ease-in-out;
    }
}

.hamburger {
    display: flex;
    flex-direction: column;
    width: 33px;
    position: relative;
    top: -2px;

    span {
        display: inline-block;
        background-color: var(--white);
        width: 100%;
        height: 2px;
        border-radius: 10px;
        margin: 5px 0;
        transition: all 0.2s ease-in-out;
    }

    &.active {
        span {
            &:nth-child(1) {
                transform: rotate(45deg) translate(6px, 5px);
            }

            &:nth-child(2) {
                transform: rotate(-45deg) translate(4px, -2px);
            }
        }
    }
}

.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background-color: var(--midnight);
    display: flex;
    align-items: center;
    max-height: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;

    &.active {
        max-height: 100%;
        opacity: 1;
        transition: max-height 0.4s ease-in-out, opacity 0.3s ease-in-out;
    }

    .container {
        position: static;
    }

    ul {
        padding: 0;
        margin: 0;
        font-size: 34px;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -1.92px;
        font-family: "Plus Jakarta Sans", sans-serif;

        @media (max-height: 420px) and (max-width: 990px) {
            font-size: 16px;
        }

        @include breakpoint-up('lg') {
            font-size: 48px;
            margin: 0 0 70px;
        }

        li {
            list-style: none;
            margin-bottom: 24px;

            @media (max-height: 420px) and (max-width: 990px) {
                margin: 10px 0;
            }

            a {
                color: var(--white);

                &:hover {
                    color: var(--blue-accent);
                }
            }

            &.active {
                a {
                    color: var(--blue-accent);
                }
            }
        }
    }

    .ln {
        @include breakpoint-down('lg') {
            position: absolute;
            bottom: 33px;
        }
    }
}