.btn {
    display: inline-block;
    border: 1px solid var(--white);
    padding: 25px 74px 25px 16px;
    color: var(--white);
    font-size: 1.1rem;
    background: url('../media/arrow.svg') no-repeat right 16px center;
    text-align: left;
    min-width: auto;
    width: 100%;
    outline: none;
    transition: all 0.3s ease;
    cursor: pointer;

    &.white {
        background-color: var(--white);
        color: var(--blue-dark);
    }

    &.blue {
        background: var(--blue-accent) url('../media/arrow-dark.svg') no-repeat right 16px center;
        border-color: var(--blue-accent);
    }

    @include breakpoint-up('md') {
        width: auto;
        min-width: 282px;
    }

    &:hover {
        font-weight: bold;
        transition: all 0.3s ease;
    }

    &.transparent {
        &:hover {
            background-color: var(--white);
            color: var(--blue-accent);
        }   
    }
}

.show-more {
    &-wrap {
        display: flex;
        justify-content: center;
        margin: 30px 0 60px 0;

        @include breakpoint-up('md') {
            margin: 2.569vw 0 13.611vw;
        }
    }

    &-cta {
        display: inline-block;
        padding: 14px 19px;
        background-color: var(--white);
        color: var(--blue-accent);
        font-size: 18px;
        transition: all 0.3s ease;

        &.is-hidden {
            display: none;
        }

        &:hover {
            transition: all 0.3s ease;
            font-weight: bold;
        }
    }
}

.ln {
    display: flex;
    width: 53px;
    height: 53px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='53' height='53' viewBox='0 0 53 53' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='26.5' cy='26.5' r='26.5' fill='white'/%3E%3Cpath d='M39 28.7143V38H33.6415V29.336C33.6415 27.159 32.8662 25.6741 30.9272 25.6741C29.4467 25.6741 28.5649 26.6758 28.1778 27.6435C28.0363 27.9895 28 28.4715 28 28.956V38H22.639C22.639 38 22.7116 23.3272 22.639 21.8058H28V24.1011L27.9649 24.1539H28V24.1011C28.7114 23 29.9828 21.4258 32.8299 21.4258C36.3558 21.4258 39 23.7412 39 28.7143ZM17.0325 14C15.2 14 14 15.2081 14 16.7974C14 18.3515 15.1649 19.5961 16.9623 19.5961H16.9974C18.8675 19.5961 20.0286 18.3515 20.0286 16.7974C19.996 15.2081 18.8675 14 17.0325 14ZM14.3182 38H19.6766V21.8058H14.3182V38Z' fill='%231D1D1D'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    transition: background 0.3s ease;

    &:hover {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='53' height='53' viewBox='0 0 53 53' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='26.5' cy='26.5' r='26.5' fill='%23008ECF'/%3E%3Cpath d='M39 28.7143V38H33.6415V29.336C33.6415 27.159 32.8662 25.6741 30.9272 25.6741C29.4467 25.6741 28.5649 26.6758 28.1778 27.6435C28.0363 27.9895 28 28.4715 28 28.956V38H22.639C22.639 38 22.7116 23.3272 22.639 21.8058H28V24.1011L27.9649 24.1539H28V24.1011C28.7114 23 29.9828 21.4258 32.8299 21.4258C36.3558 21.4258 39 23.7412 39 28.7143ZM17.0325 14C15.2 14 14 15.2081 14 16.7974C14 18.3515 15.1649 19.5961 16.9623 19.5961H16.9974C18.8675 19.5961 20.0286 18.3515 20.0286 16.7974C19.996 15.2081 18.8675 14 17.0325 14ZM14.3182 38H19.6766V21.8058H14.3182V38Z' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        transition: background 0.3s ease;
    }
}
