.content-section {
    padding: 52px 0 47px;

    @include breakpoint-up('md') {
        padding: 9.722vw 0 3.750vw;
    }

    &.up-section {
        padding: 20px 0;

        @include breakpoint-up('md') {
            padding: 0;
        }
    }

    .content {
        margin-bottom: 50px;

        @include breakpoint-up('md') {
            margin-bottom: 6.944vw;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &-box {
            p {
                margin-bottom: 15px;

                @include breakpoint-up('md') {
                    margin-bottom: 2.672vw;
                }

                a {
                    color: var(--white);
                    text-decoration: underline;
    
                    &:hover {
                        color: var(--blue-accent);
                    }
                
                }
            }

            ul {
                margin: 0 0 55px;
                padding: 0 0 0 18px;
            
                @include breakpoint-up('md') {
                    margin-bottom: 2.672vw;
                }

                li {
                    margin-bottom: 15px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 140%;
                    letter-spacing: -0.36px;

                    @include breakpoint-up('md') {
                        margin: 0 0 24px;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    h3 {
        margin-bottom: 40px;

        @include breakpoint-up('md') {
            margin-bottom: 5.056vw;
        }
    }

    h5 {
        color: var(--blue-accent);
        font-weight: 700;
        margin-bottom: 16px;
        font-family: "Plus Jakarta Sans", sans-serif;

        &.bullet {
            padding-left: 30px;
            position: relative;

            @include breakpoint-up('md') {
                padding-left: 45px;
            }

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                margin-top: 3px;
                left: 10px;
                transform: translateY(-50%);
                width: 10px;
                height: 10px;
                background: var(--blue-accent);
                border-radius: 50%;

                @include breakpoint-up('md') {
                    left: 14px;
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }

    p {
        margin-bottom: 0;
    }
}

.contect-box-m-bot {
    margin-bottom: 30px;
}

.info-cards {
    margin-bottom: 60px;

    @include breakpoint-up('md') {
        margin-bottom: 6.250vw;

        .col-md-6 {
            &:nth-child(1),
            &:nth-child(4) {
                margin-top: 0;
            }
    
            &:nth-child(2) {
                margin-top: 12.778vw;
            }
    
            &:nth-child(3) {
                margin-top: -12.778vw;
            }
    
            &:nth-child(2n+5) {
                margin-top: -12.778vw;
            }
        }
    }

    @include breakpoint-up('lg') {
        gap: 40px;
    }
}

.info-card {
    margin-bottom: 5px;

    @include breakpoint-up('md') {
        margin-bottom: 54px;
    }   

    img {
        margin-bottom: 8px;
        width: 100%;
    }

    h5 {
        margin: 0 0 8px;
        color: var(--white);
    }

    p {
        font-size: 16px;
        margin: 0;

        @include breakpoint-up('md') {
            font-size: 1.389vw;
        }

        @include breakpoint-up('lg') {
            font-size: 20px;
        }
    }
}

.team-cards {
    margin-bottom: 60px;

    @include breakpoint-up('md') {
        margin-bottom: 6.250vw;

        .col-md-6 {
            &:nth-child(1),
            &:nth-child(4) {
                margin-top: 0;
            }
    
            &:nth-child(2) {
                margin-top: 4.167vw;
            }
    
            &:nth-child(3) {
                margin-top: -4.167vw;
            }
    
            &:nth-child(2n+5) {
                margin-top: -4.167vw;
            }
        }
    }

    @include breakpoint-up('lg') {
        gap: 40px;
    }
}

.team-card {
    margin-bottom: 5px;

    @include breakpoint-up('md') {
        margin-bottom: 54px;
    }   

    img {
        margin-bottom: 8px;
        width: 100%;
    }

    h5 {
        margin: 0 0 8px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include breakpoint-up('lg') {
            white-space: nowrap;
        }

        .name {
            white-space: nowrap;
            padding-right: 14px;
            margin-right: 12px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 100%;
                background: var(--blue-accent);
            }
        }

        .status {
            white-space: nowrap;
            color: var(--mischka);
            
            font-size: 16px;

            @include breakpoint-up('md') {
                font-size: 1.667vw;
            }

            @include breakpoint-up('lg') {
                font-size: 24px;
            }
        }
    }

    p {
        font-size: 16px;
        margin-bottom: 8px;

        @include breakpoint-up('md') {
            font-size: 1.389vw;
        }

        @include breakpoint-up('lg') {
            font-size: 20px;
        }
    }
}

.social {
    display: flex;

    a {
        &.ln {
            display: flex;
            width: 40px;
            height: 40px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='53' height='53' viewBox='0 0 53 53' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='26.5' cy='26.5' r='26.5' fill='white'/%3E%3Cpath d='M39 28.7143V38H33.6415V29.336C33.6415 27.159 32.8662 25.6741 30.9272 25.6741C29.4467 25.6741 28.5649 26.6758 28.1778 27.6435C28.0363 27.9895 28 28.4715 28 28.956V38H22.639C22.639 38 22.7116 23.3272 22.639 21.8058H28V24.1011L27.9649 24.1539H28V24.1011C28.7114 23 29.9828 21.4258 32.8299 21.4258C36.3558 21.4258 39 23.7412 39 28.7143ZM17.0325 14C15.2 14 14 15.2081 14 16.7974C14 18.3515 15.1649 19.5961 16.9623 19.5961H16.9974C18.8675 19.5961 20.0286 18.3515 20.0286 16.7974C19.996 15.2081 18.8675 14 17.0325 14ZM14.3182 38H19.6766V21.8058H14.3182V38Z' fill='%231D1D1D'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            transition: background 0.3s ease;

            @include breakpoint-up('md') {
                width: 53px;
                height: 53px;
            }
        
            &:hover {
                background-image: url("data:image/svg+xml,%0A%3Csvg width='53' height='53' viewBox='0 0 53 53' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='26.5' cy='26.5' r='26.5' fill='%23008ECF'/%3E%3Cpath d='M39 28.7143V38H33.6415V29.336C33.6415 27.159 32.8662 25.6741 30.9272 25.6741C29.4467 25.6741 28.5649 26.6758 28.1778 27.6435C28.0363 27.9895 28 28.4715 28 28.956V38H22.639C22.639 38 22.7116 23.3272 22.639 21.8058H28V24.1011L27.9649 24.1539H28V24.1011C28.7114 23 29.9828 21.4258 32.8299 21.4258C36.3558 21.4258 39 23.7412 39 28.7143ZM17.0325 14C15.2 14 14 15.2081 14 16.7974C14 18.3515 15.1649 19.5961 16.9623 19.5961H16.9974C18.8675 19.5961 20.0286 18.3515 20.0286 16.7974C19.996 15.2081 18.8675 14 17.0325 14ZM14.3182 38H19.6766V21.8058H14.3182V38Z' fill='white'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: center;
                transition: background 0.3s ease;
            }
        }

        &.x {
            display: flex;
            width: 40px;
            height: 40px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' fill='white'/%3E%3Cg clip-path='url(%23clip0_629_1075)'%3E%3Cmask id='mask0_629_1075' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='11' y='12' width='17' height='17'%3E%3Cpath d='M11 12H28V29H11V12Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_629_1075)'%3E%3Cpath d='M24.3875 12.7964H26.9946L21.2996 19.322L28 28.2032H22.7543L18.6427 22.8179L13.9434 28.2032H11.3339L17.4248 21.2211L11 12.7976H16.3793L20.0901 17.7191L24.3875 12.7964ZM23.4707 26.6392H24.9157L15.59 14.279H14.0406L23.4707 26.6392Z' fill='%233D3D3D'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_629_1075'%3E%3Crect width='17' height='17' fill='white' transform='translate(11 12)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            transition: background 0.3s ease;
            margin-left: 8px;

            @include breakpoint-up('md') {
                width: 53px;
                height: 53px;
            }
        
        
            &:hover {
                background-image: url("data:image/svg+xml,%0A%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='20' r='20' fill='%23008ECF'/%3E%3Cg clip-path='url(%23clip0_629_1090)'%3E%3Cmask id='mask0_629_1090' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='11' y='12' width='17' height='17'%3E%3Cpath d='M11 12H28V29H11V12Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_629_1090)'%3E%3Cpath d='M24.3875 12.7964H26.9946L21.2996 19.322L28 28.2032H22.7543L18.6427 22.8179L13.9434 28.2032H11.3339L17.4248 21.2211L11 12.7976H16.3793L20.0901 17.7191L24.3875 12.7964ZM23.4707 26.6392H24.9157L15.59 14.279H14.0406L23.4707 26.6392Z' fill='white'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_629_1090'%3E%3Crect width='17' height='17' fill='white' transform='translate(11 12)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: center;
                transition: background 0.3s ease;
            }
        }
    }
}