footer {
    padding: 40px 0 35px;
    border-top: 1px var(--blue-dark) solid;

    @include breakpoint-up('lg') {
        padding: 80px 0 0;
    }

    h5 {
        color: var(--blue-accent);
    }

    .h5subtitle {
        font-size: 24px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.48px;
        font-family: "Plus Jakarta Sans", sans-serif;
        color: var(--mischka);
    }

    .form {
        @include breakpoint-down('lg') {
            margin-top: 45px;
        }
    }

    form {
        .mc-field-group {
            margin-bottom: 24px;
        }

        .input {
            display: flex;
            flex-direction: column;

            label {
                color: var(--mischka);
                font-size: 14px;
                font-family: "Plus Jakarta Sans", sans-serif;
                margin-bottom: 5px;
            }

            input {
                background: none;
                height: 38px;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                font-family: "Plus Jakarta Sans", sans-serif;
                letter-spacing: -0.48px;
                color: var(--white);
                border: 0;
                border-bottom: 2px var(--blue-accent) solid;

                /*placeholder color*/
                &::placeholder {
                    color: var(--waikawa-grey);
                }
            }
        }

        @include breakpoint-down('md') {
            button.btn {
                width: 100%;
            }
        }
    }
    
    #mc_embed_signup input.mce_inline_error {
        border-color: var(--red);
    }

    #mc_embed_signup div.mce_inline_error {
        background: none;
        color: var(--red);
        font-size: 14px;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: normal; 
        padding: 0;
        margin: 2px 0 0;    
    }
}
.checkbox-input {
    margin-bottom: 24px;
}

.footer-nav {
    padding: 0;
    margin: 0 0 24px;
    font-size: 22px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.88px;
    font-family: "Plus Jakarta Sans", sans-serif;

    @include breakpoint-down('lg') {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 55px;
    }

    @include breakpoint-up('md') {
        font-size: 2.500vw;
        margin: 0 0 27px;
        letter-spacing: -1.44px;
    }

    li {
        list-style: none;
        margin-bottom: 18px;

        a {
            color: var(--white);

            &:hover {
                color: var(--blue-accent);
            }
        }
    }
}

.copy {
    padding: 34px 0 0;
    margin-top: 60px;
    border-top: 1px var(--blue-dark) solid;

    @include breakpoint-up('lg') {
        padding: 34px 0;
    }
    
    .container {
        @include breakpoint-up('lg') {
            display: flex;
        }
    }

    p {
        margin: 0;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.28px;
        opacity: 0.4;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.28px;

        @include breakpoint-up('lg') {
            display: flex;
        }

        li {
            margin: 24px 0 0 0;

            @include breakpoint-up('lg') {
                margin: 0 0 0 28px;
            }

            a {
                color: var(--white);
                opacity: 0.4;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}