.container {
    position: relative;
    z-index: 1;
}

.fixed {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.m-order {
    &-1 {
        @include breakpoint-down('md') {
            order: 1;
        }
    }

    &-2 {
        @include breakpoint-down('md') {
            order: 2;
        }
    }
}