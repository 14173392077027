:root {
	--white: #FFFFFF;
	--black: #000000;
	--grey-dark: #3d3d3d;
	--waikawa-grey: #707070;
	--mischka: #cdd0d5;
	--blue-dark: #0C4574;
	--blue-accent: #008ECF;
	--blue-softer: #EBF6FB;
	--blue-soft: #C5E3F1;
	--blue-soft-light: #e8f4f9;
	--midnight: #1D1D1D;
	--red: #FF1C1C;
}

*:focus,
*:focus-visible {
	outline: none;
}

html {
	overflow: auto;
	scrollbar-width: none;
	-ms-overflow-style: none; 
}
  
html::-webkit-scrollbar {
	display: none;
}
  

body {
	font-family: "Inter", sans-serif;
	color: var(--white);
	-webkit-font-smoothing: antialiased;
	background-color: var(--midnight);
}

a {
	text-decoration: none;
}
